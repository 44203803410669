<template>
    <div id="allBody">
        <van-nav-bar title="商品评价" fixed placeholder @click-left="onClickLeft" left-arrow />
        <!-- 商品全部评价 -->
        <div class="bodyList">
            <van-list v-model="isLoading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                <van-cell v-for="(val) in goodsEvaluateListAll" :key="val.id" class="container-evaluateList-body-every">
                    <div class="container-evaluateList-body-every-top">
                        <div>
                            <van-image round width="35" height="35" :src="val.headimgUrl" />
                        </div>
                        <div class="container-evaluateList-body-every-top-name">
                            <p class="container-evaluateList-body-every-top-name-one">{{ val.nickName }}</p>
                            <p>{{ val.diffTime }}</p>
                        </div>
                    </div>
                    <div class="container-evaluateList-body-every-main">
                        <p class="container-evaluateList-body-every-main-top">整体评价：{{ val.content || '该用户未作出评价' }}</p>
                        <div v-if="val.picUrlsList && val.picUrlsList.length > 0"
                            class="container-evaluateList-body-every-main-img">
                            <van-image v-for="(item, index) in val.picUrlsList" :key="index"
                                class="container-evaluateList-body-every-main-img-one" width="2.1rem" height="2.1rem"
                                fit="contain" :src="item ? item : require('../../assets/img/touxiang.png')"
                                @click="previewImg(val.picUrlsList, index)" />
                        </div>
                        <p v-if="val.sellerReply" class="container-evaluateList-body-every-main-bottom">商家回复：{{
                            val.sellerReply
                        }}
                        </p>
                    </div>
                </van-cell>
            </van-list>

        </div>
    </div>
</template>
  
<script>
import { goodsEvaluate } from "@/api/flMall";
import { Toast, ImagePreview } from "vant";
export default {
    data() {
        return {
            evaluateInfo: {
                size: 10,
                current: 1
            },
            finished: false,
            isLoading: false, //三个变量默认flase
            goodsEvaluateListAll: [],//商品评价
            goodsSum: 0,//商品评价总数
        };
    },

    created() {
        if (this.$route.query.session) {
            this.$store.commit("setSession", this.$route.query.session);
        }
    },

    mounted() {
        let screenWidth = document.documentElement.getBoundingClientRect().width;
        document.documentElement.style.fontSize = screenWidth / 10 + "px";
        this.getGoodsEvaluate({ size: 10, current: 1, spuId: this.$route.query.id })
    },

    methods: {
        //预览图片
        previewImg(data, index) {
            ImagePreview({ images: [...data], startPosition: index, closeable: true, })
        },
        //上拉加载
        onLoad() {
            // 异步更新数据
            // setTimeout 仅做示例，真实场景中一般为 ajax 请求
            this.evaluateInfo.current++
            this.isLoading = true;
            setTimeout(() => {
                // 数据全部加载完成
                if (this.goodsEvaluateListAll.length >= this.goodsSum) {
                    this.finished = true;
                    this.isLoading = false;
                } else {
                    this.getGoodsEvaluate({ ...this.evaluateInfo, spuId: this.$route.query.id })
                }
            }, 1000);
        },
        //获取评价
        getGoodsEvaluate(data) {  //使用type判断是展示全部还是只展示3个评价
            goodsEvaluate(data).then(res => {
                console.log(res, '<==商品评价');
                if (res.data.code == 0) {
                    this.goodsSum = res.data.data.total
                    //展示全部
                    this.goodsEvaluateListAll.push(...res.data.data.records)
                    this.finished = true
                    this.isLoading = false;
                    this.goodsEvaluateListAll = this.goodsEvaluateListAll.map(v => {
                        if (v.picUrls) {
                            v.picUrlsList = v.picUrls.split(',')
                        }
                        return v
                    })
                }
            }).catch(() => {
                Toast.fail("加载失败请重试");
            });
        },
        getPage() {
            this.loading = true;
            const id = this.$route.query.id;
            this.getGoodsEvaluate({ spuId: id, size: 10, current: 1 })
        },
        onClickLeft() {
            this.$router.back()
        }
    }
}
</script>
  
<style lang="less" scoped>
#allBody {
    background: #f6f6f6;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    // padding: 0 2px;

    /deep/ .van-nav-bar__left,
    .van-nav-bar__right {
        padding: 0 15px;
    }

    .bodyList {
        width: 100%;
        height: 100%;
        background: white;
        padding: 4px 5px;
        box-sizing: border-box;

        &-every {
            padding-bottom: 5px;
            margin-bottom: 5px;
            border-bottom: 1px solid #e9e9e9;

            &-top {
                display: flex;
                align-items: center;

                &-name {
                    margin-left: 10px;
                    font-size: 12px;
                    display: flex;
                    flex-direction: column;

                    &-one {
                        font-weight: 600;
                    }
                }
            }

            &-main {
                font-size: 12px;
                padding: 0 5px;

                &-top {
                    text-overflow: -o-ellipsis-lastline;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    line-clamp: 2;
                    -webkit-box-orient: vertical;

                }

                &-img {
                    padding: 5px 0;

                    &-one {
                        margin-right: 10px;
                    }
                }

                &-bottom {
                    padding: 0 5px;
                    text-overflow: -o-ellipsis-lastline;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    line-clamp: 2;
                    -webkit-box-orient: vertical;

                }
            }
        }
    }
}
</style>
  